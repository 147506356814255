import { ChartData } from 'common/core/models/Report';
import { GetUserReportsResponse } from 'modules/users/use-cases/GetUserReports/types';
import { PaginateParamsT, PaginateResponseT } from 'common/PaginationTypes';
import { ReportFiltersResponse } from 'modules/reports/use-cases/GetFilters/types';
import {
  ReportFiltersView,
  ReportView
} from 'modules/reports/domain/ReportView';
import { SaveDashboardReportRequest } from 'modules/reports/use-cases/SaveReportToDashboard/types';
import fetchJson from 'utils/fetchJson';

export type ReportPaginateResponse = PaginateResponseT<{
  reports: ReportView[];
}>;

export type ReportSaveProps = {
  name: string;
  location: string;
  filters: ReportFiltersView;
  showOnDashboard: boolean;
  dashboardOnly?: boolean;
  facilityMapId?: string;
  client: string;
  chartData?: ChartData;
  tableHeaders?: string[];
  sort?: string;
};

export const paginateReports = async (
  params: PaginateParamsT & {
    location: string;
  }
): Promise<ReportPaginateResponse> => {
  const url = `/api/reports?${new URLSearchParams({
    limit: params.limit.toString(),
    offset: params.offset.toString(),
    location: params.location,
    dashboardOnly: 'false'
  })}`;
  const reports = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return reports;
};

export const getReportFilters = async (
  locationId: string,
  locationClientId?: string
): Promise<ReportFiltersResponse> => {
  const response = await fetchJson(
    `/api/reports/filters?locationId=${locationId}&clientId=${locationClientId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return response;
};

export const createReport = async (
  report: ReportSaveProps,
  redirect = true,
  successModel = 'Report'
) => {
  const url = `/api/reports`;
  const saved = await fetchJson(
    url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(report)
    },
    successModel,
    redirect
  );
  return saved;
};

export const updateReport = async (report: ReportView) => {
  const url = `/api/reports/${report.id}`;
  const saved = await fetchJson(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(report)
    },
    'Report'
  );
  return saved;
};

export const saveDashboardReport = async (req: SaveDashboardReportRequest) => {
  const url = `/api/reports/dashboard`;
  const saved = await fetchJson(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    },
    'Report'
  );
  return saved;
};
export const getDashboardReports = async (
  locationId: string
): Promise<GetUserReportsResponse> => {
  const response = await fetchJson(
    `/api/reports/user?locationId=${locationId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return response;
};

export const getReport = async (id: string): Promise<ReportView> => {
  const report = await fetchJson(`/api/reports/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return report;
};

export const deleteReport = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/reports/${id}`,
    {
      method: 'DELETE'
    },
    'Report'
  );
  return removed;
};

export const listDashboardReports = async (
  locationId: string
): Promise<ReportView[]> => {
  const url = `/api/reports?${new URLSearchParams({
    limit: '10',
    offset: '0',
    showOnDashboard: 'true',
    location: locationId,
    sort: 'createdAt:1'
  })}`;
  const results = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return results?.reports;
};

export const listDefaultReports = async (
  locationId: string
): Promise<ReportView[]> => {
  const url = `/api/reports/dashboard?${new URLSearchParams({
    location: locationId
  })}`;
  const defaultResults = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return defaultResults;
};

export const downloadReportPDF = async (id: string, data: any) => {
  const url = `/api/reports/${id}/pdf`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  if (response.status !== 200) {
    return false;
  }
  return response.blob();
};
