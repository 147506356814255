import { ReportView } from 'modules/reports/domain/ReportView';
import CapaModule from './CapaModule';
import React, { FC } from 'react';
import SwabModule from './SwabModule';
import dynamic from 'next/dynamic';

const ResultsByOrganism = dynamic(
  () => import('components/Dashboard/ResultsByOrganism')
);
const ReportGraph = dynamic(() => import('components/Dashboard/ReportGraph'));
const EbacmapImage = dynamic(() => import('components/Dashboard/EbacmapImage'));

const DashboardModule = dynamic(
  () => import('components/Report/DashboardModule')
);
const ResultsByNonconformances = dynamic(
  () => import('components/Dashboard/ResultsByNonconformances')
);

export const ItemTypes = {
  CARD: 'card'
};

export interface DnDWidgetType {
  id: string;
  report: ReportView;
  saveReportConfig: (report: ReportView, newConfig: any) => void;
  removeReport: (id: string) => void;
  canViewReports: boolean;
  canEditReports: boolean;
}

const style = {
  cursor: 'move',
  transition: 'all 300ms ease-in-out',
  position: 'relative',
  width: '100%'
};

export const DndWidget: FC<DnDWidgetType> = ({
  report,
  canEditReports,
  saveReportConfig,
  removeReport,
  canViewReports
}) => {
  if (report.name === 'ResultsByOrganism')
    return (
      <DashboardModule
        style={{ ...style }}
        title="No. Tests By Subcategory"
        id={report.id}
        reportId={report.id}
        report={report}
        allowView={false}
        allowRemove={canEditReports}
        allowEdit={canEditReports}
        saveCallback={config => saveReportConfig(report, config)}
        removeCallback={removeReport}
        ReportComponent={
          <ResultsByOrganism
            config={{
              location: report.location,
              client: report.client,
              interval: report.filters.interval,
              dateRange: report.filters.dateRangeType
            }}
          />
        }
      />
    );
  if (report.name === 'Nonconformances') {
    return (
      <DashboardModule
        style={{ ...style }}
        title=""
        id={report.id}
        reportId={report.id}
        allowView={false}
        allowRemove={canEditReports}
        allowEdit={false}
        saveCallback={() => {}}
        removeCallback={removeReport}
        ReportComponent={
          <ResultsByNonconformances
            config={{
              location: report.location,
              client: report.client
            }}
          />
        }
      />
    );
  }

  if (report.name === 'SwabScheduler') {
    return (
      <DashboardModule
        style={{ ...style }}
        title="Sample Scheduler"
        id={report.id}
        reportId={report.id}
        allowView={true}
        allowRemove={true}
        allowEdit={false}
        saveCallback={() => {}}
        removeCallback={removeReport}
        ReportComponent={<SwabModule />}
        reportLink={'/sample-scheduler/calendar'}
      />
    );
  }

  if (report.name === 'CAPA') {
    return (
      <DashboardModule
        style={{ ...style }}
        title="CAPA"
        id={report.id}
        reportId={report.id}
        allowView={true}
        allowRemove={true}
        allowEdit={false}
        saveCallback={() => {}}
        removeCallback={removeReport}
        ReportComponent={<CapaModule />}
        reportLink={'/capa/new'}
      />
    );
  }

  return (
    <DashboardModule
      key={report.id}
      style={{ ...style }}
      id={report.id}
      reportId={report.id}
      title={report.name}
      report={report}
      allowView={canViewReports}
      allowRemove={canEditReports}
      allowEdit={!report.dashboardOnly && canEditReports}
      reportLink={
        report.dashboardOnly && report.filters.reportType
          ? `/ebacmap/${report.id}`
          : `/reports/${report.id}`
      }
      saveCallback={config => saveReportConfig(report, config)}
      removeCallback={removeReport}
      ReportComponent={
        report.dashboardOnly &&
        report.filters.reportType &&
        report.name !== '360 Tour' ? (
          <EbacmapImage facilityMapId={report.facilityMapId || ''} />
        ) : report.dashboardOnly &&
          report.filters.reportType &&
          report.name === '360 Tour' ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            width={'100%'}
            height={250}
            src={'/images/360-logo.png'}
            alt="report-thumbnail"
          />
        ) : (
          <ReportGraph report={report} />
        )
      }
    />
  );
};
