import { CircularProgress } from '@react-md/progress';
import { Grid, GridCell } from '@react-md/utils';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { Typography } from '@react-md/typography';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCapaDashboardReport } from 'services/capa';
import { hasPermission } from 'utils/permissions';
import { namespaceConfig } from 'fast-redux';
import { reduxPage } from 'hooks/withRedux';
import { useBetween } from 'use-between';
import { useLocation, useUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import router from 'next/router';
import styles from './SwabModule.module.scss';

const DEFAULT_STATE = {
  results: {},
  loaded: false,
  isFetching: false
};

const { action, getState: getResultsState } = namespaceConfig(
  'capa-results',
  DEFAULT_STATE
);

const resetCapaResults = action('resetResults', () => ({
  ...DEFAULT_STATE
}));

const requestResults = action('requestResults', state => ({
  ...state,
  loaded: false,
  isFetching: true
}));

const receiveResults = action('receiveResults', (state, results) => ({
  ...state,
  isFetching: false,
  loaded: true,
  results
}));

const getResults = locationId => async dispatch => {
  dispatch(requestResults());
  const results: any = await getCapaDashboardReport(locationId);
  dispatch(receiveResults(results));
};

type CapaModulePropsT = {
  isFetching: boolean;
  loaded: boolean;
  results: any;
  getResults: (any) => void;
  resetCapaResults: () => void;
};

const CapaModule = (props: CapaModulePropsT) => {
  const r = router;
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const { user } = useUser();
  const [hasCapaManage, setHasCapaManage] = useState<boolean>(false);
  const { results, isFetching, loaded, getResults, resetCapaResults } = props;
  useEffect(() => {
    if (!isFetching && !loaded) {
      getResults(location?.id);
    }
  }, [results, isFetching, loaded]);

  useEffect(() => {
    if (user && location) {
      setHasCapaManage(
        hasPermission(
          user,
          location.id,
          PermissionDomainValue.Capa,
          PermissionActionValue.Manage
        )
      );
    }
  }, [user]);

  useEffect(() => {
    return () => {
      // this gets called on leave
      resetCapaResults();
    };
  }, []);

  const handleClick = (type: string) => {
    if (hasCapaManage) {
      if (type === 'non-conformances') {
        r.push(`/capa/non-conformances`);
      } else if (type === 'open') {
        r.push(`/capa/reports?status=open`);
      } else if (type === 'week') {
        r.push(`/capa/reports?status=closed&dateRange=week`);
      } else {
        r.push(`/capa/reports?status=closed&dateRange=month`);
      }
    }
  };

  if (isFetching && !loaded) {
    return <CircularProgress id="swabModuleLoading" color="primary" />;
  }

  return (
    <Grid className={styles.cardContainer}>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('non-conformances')}
          className={styles.numberContainer}
        >
          {results?.nonconformanceResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          New Non-conformances
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('open')}
          className={styles.numberContainer}
        >
          {results?.openResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Open CAPA Reports
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('week')}
          className={styles.numberContainer}
        >
          {results?.weeksResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Closed CAPA Reports Last 7 Days
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('month')}
          className={styles.numberContainer}
        >
          {results?.monthsResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Closed CAPA Reports Last 30 Days
        </Typography>
      </GridCell>
    </Grid>
  );
};

function mapStateToProps(state) {
  const { isFetching, loaded, results } = getResultsState(state);
  return {
    isFetching,
    loaded,
    results
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResults, resetCapaResults }, dispatch);
}

export default reduxPage(
  connect(mapStateToProps, mapDispatchToProps)(CapaModule)
);
