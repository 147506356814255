import { CircularProgress } from '@react-md/progress';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { namespaceConfig } from 'fast-redux';
import { reduxPage } from 'hooks/withRedux';
import { useBetween } from 'use-between';
import React, { useEffect, useState } from 'react';
import router from 'next/router';

import { Grid, GridCell } from '@react-md/utils';
import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { Typography } from '@react-md/typography';
import { getDashboardReport } from 'services/swabSchedules';
import { hasPermission } from 'utils/permissions';
import { useLocation, useUser } from 'hooks';
import styles from './SwabModule.module.scss';

const DEFAULT_STATE = {
  results: {},
  loaded: false,
  isFetching: false
};

const { action, getState: getResultsState } = namespaceConfig(
  'swab-schedule-results',
  DEFAULT_STATE
);

const resetSwabResults = action('resetResults', () => ({
  ...DEFAULT_STATE
}));

const requestResults = action('requestResults', state => ({
  ...state,
  loaded: false,
  isFetching: true
}));

const receiveResults = action('receiveResults', (state, results) => ({
  ...state,
  isFetching: false,
  loaded: true,
  results
}));

const getResults = locationId => async dispatch => {
  dispatch(requestResults());
  const results: any = await getDashboardReport(locationId);
  dispatch(receiveResults(results));
};

type SwabModulePropsT = {
  isFetching: boolean;
  loaded: boolean;
  results: any;
  getResults: (any) => void;
  resetSwabResults: () => void;
};

const SwabModule = (props: SwabModulePropsT) => {
  const r = router;
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const { user } = useUser();
  const [hasSwabManage, setHasSwabManage] = useState<boolean>(false);
  const { results, isFetching, loaded, getResults, resetSwabResults } = props;
  useEffect(() => {
    if (!isFetching && !loaded) {
      getResults(location?.id);
    }
  }, [results, isFetching, loaded]);

  useEffect(() => {
    if (user && location) {
      setHasSwabManage(
        hasPermission(
          user,
          location.id,
          PermissionDomainValue.SwabSchedule,
          PermissionActionValue.Manage
        )
      );
    }
  }, [user]);

  useEffect(() => {
    return () => {
      // this gets called on leave
      resetSwabResults();
    };
  }, []);

  const handleClick = (type: string) => {
    if (hasSwabManage) {
      if (type === 'pastdue') {
        r.push(`/sample-scheduler/calendar?status=pastdue&view=month`);
      } else if (type === 'unscheduled') {
        let url = `/api/swab-schedules/report?reportType=unscheduled&locationId=${
          location!.id
        }`;
        window.open(url);
      } else if (type === 'day') {
        r.push(`/sample-scheduler/calendar?status=upcoming&view=day`);
      } else {
        r.push(`/sample-scheduler/calendar?status=upcoming&view=week`);
      }
    }
  };

  if (isFetching && !loaded) {
    return <CircularProgress id="swabModuleLoading" color="primary" />;
  }

  return (
    <Grid className={styles.cardContainer}>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('pastdue')}
          className={styles.numberContainer}
        >
          {results?.overdueResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Past Due Tests
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('unscheduled')}
          className={styles.numberContainer}
        >
          {results?.unscheduledSites}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Unscheduled Sites
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('day')}
          className={styles.numberContainer}
        >
          {results?.todaysResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Upcoming Tests Today
        </Typography>
      </GridCell>
      <GridCell colSpan={6}>
        <Typography
          type="headline-3"
          onClick={() => handleClick('week')}
          className={styles.numberContainer}
        >
          {results?.weeksResults}
        </Typography>
        <Typography type="body-2" className={styles.titleContainer}>
          Upcoming Tests This Week
        </Typography>
      </GridCell>
    </Grid>
  );
};
function mapStateToProps(state) {
  const { isFetching, loaded, results } = getResultsState(state);
  return {
    isFetching,
    loaded,
    results
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResults, resetSwabResults }, dispatch);
}

export default reduxPage(
  connect(mapStateToProps, mapDispatchToProps)(SwabModule)
);
