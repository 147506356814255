import { BlackoutDatePaginateResponse } from 'modules/swab-schedule/repos/BlackoutDateRepo';
import { CalendarEventView } from 'modules/swab-schedule/use-cases/GetSwabCalendar/dtos';
import { DuplicateResponse } from 'modules/swab-schedule/use-cases/CreateBlackoutDate';
import { PaginateParamsT } from 'common/PaginationTypes';
import { SwabDashboardResponse } from 'modules/swab-schedule/use-cases/GetSwabDashboard/UseCase';
import { SwabSchedulePaginateResponse } from 'modules/swab-schedule/types';
import { SwabScheduleView } from 'modules/swab-schedule/domain/SwabScheduleView';
import fetchJson from 'utils/fetchJson';

interface SwabSchedulePaginatParamsT extends PaginateParamsT {
  locationId: string;
  zoneId?: string;
  siteId?: string;
  testDefinitionId?: string;
}

interface BlackoutDatePaginatParamsT extends PaginateParamsT {
  locationId: string;
}

interface SwabCalendarParams {
  locationId: string;
  zoneId?: string;
  siteId?: string;
  testDefinitionId?: string;
  calendarType: string;
  startDate: Date;
  endDate: Date;
  sort?: string;
}

interface SwabDateExistingParams {
  id: string;
  dateId: string;
  scheduledDate: Date;
  frequency: string;
  operationType: string;
  testDefinitionId: string;
  zoneCode: string;
  locationId: string;
}

interface BlackoutDateCreateProps {
  locationId: string;
  blackoutDate: Date;
  cancelAll?: boolean;
  duplicateStrategy?: string;
  existingTests?: boolean;
}

export const paginateSwabSchedules = async (
  params: SwabSchedulePaginatParamsT
): Promise<SwabSchedulePaginateResponse> => {
  const { limit, offset, sort, locationId, ...rest } = params;
  const url = `/api/swab-schedules?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    locationId,
    ...rest
  })}&sort=${sort}`;
  const swabSchedules = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return swabSchedules;
};

export const paginatePendingSwabSchedules = async (
  params: SwabSchedulePaginatParamsT
): Promise<SwabSchedulePaginateResponse> => {
  const { limit, offset, sort, locationId, ...rest } = params;
  const url = `/api/swab-schedules/pending?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    locationId,
    ...rest
  })}&sort=${sort}`;
  const swabSchedules = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return swabSchedules;
};

export const getSwabScheduleById = async (
  id: string
): Promise<SwabScheduleView> => {
  const swabSchedule = await fetchJson(`/api/swab-schedules/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return swabSchedule;
};

export const getSwabScheduleDatesById = async (
  ids: string[]
): Promise<SwabScheduleView> => {
  const swabSchedule = await fetchJson(
    `/api/swab-schedules/swab-schedule-dates`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ssDateIds: [...ids]
      })
    }
  );
  return swabSchedule;
};

export const createSwabSchedule = async (
  swabSchedule,
  redirectOnCreate
): Promise<string> => {
  const swabScheduleId = await fetchJson(
    '/api/swab-schedules',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...swabSchedule
      })
    },
    'SwabSchedule',
    redirectOnCreate
  );
  return swabScheduleId;
};

export const updateSwabSchedule = async (
  swabSchedule
): Promise<SwabScheduleView> => {
  const updatedSwabSchedule = await fetchJson(
    `/api/swab-schedules/${swabSchedule.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...swabSchedule
      })
    },
    'SwabSchedule'
  );
  return updatedSwabSchedule;
};

export const deleteSwabSchedule = async (
  id: string,
  deleteReason: string
): Promise<void> => {
  const reqObj = { note: deleteReason };
  const removed = await fetchJson(
    `/api/swab-schedules/${id}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...reqObj
      })
    },
    'SwabSchedule'
  );
  return removed;
};

export const checkForExistingTest = async (
  params: SwabDateExistingParams
): Promise<{ doesExist: boolean }> => {
  const {
    id,
    dateId,
    scheduledDate,
    frequency,
    operationType,
    testDefinitionId,
    zoneCode,
    locationId,
    ...rest
  } = params;
  const url = `/api/swab-schedules/${id}/${dateId}?${new URLSearchParams({
    scheduledDate: scheduledDate.toISOString(),
    frequency,
    operationType,
    testDefinitionId,
    zoneCode,
    locationId,
    ...rest
  })}`;
  const doesExist = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return doesExist;
};

export const getDashboardReport = async (
  locationId: string
): Promise<SwabDashboardResponse> => {
  const url = `/api/swab-schedules/dashboard?locationId=${locationId}`;
  return await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
};

export const updateSwabDate = async (swabDateObj: {
  id: string;
  dateId: string;
  completed?: boolean;
  scheduledDate?: Date;
  updateGroups?: boolean;
  sampleSubmitted?: boolean;
  notes?: string;
  image?: {
    title: string;
    filename: string;
    container: string;
    awskey: string;
    mimetype: string;
    size: number;
    url: string;
  };
}): Promise<SwabScheduleView> => {
  const updatedSwabSchedule = await fetchJson(
    `/api/swab-schedules/${swabDateObj.id}/${swabDateObj.dateId}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...swabDateObj
      })
    },
    'SwabSchedule'
  );
  return updatedSwabSchedule;
};

export const getSwabCalendars = async (
  params: SwabCalendarParams
): Promise<CalendarEventView[]> => {
  const { startDate, endDate, calendarType, locationId, sort, ...rest } =
    params;
  const url = `/api/swab-schedules/calendar?${new URLSearchParams({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    calendarType,
    locationId,
    ...rest
  })}&sort=${sort}`;
  const swabSchedules = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return swabSchedules;
};

export const updateBulkCompleteSwabDates = async (
  swabDateObjs: {
    id: string;
    dateId: string;
  }[]
): Promise<void> => {
  const completedSwabSchedules = await fetchJson(
    `/api/swab-schedules/bulk-complete`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        swabDateObjs: [...swabDateObjs]
      })
    },
    'SwabSchedule'
  );
  return completedSwabSchedules;
};

export const updateSubmittedToLabSwabDate = async (swabDateObj: {
  id: string;
  dateId: string;
}): Promise<void> => {
  const submittedSwabSchedule = await fetchJson(
    `/api/swab-schedules/submitted-to-lab`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        swabDateObj: { ...swabDateObj }
      })
    },
    'SwabSchedule'
  );
  return submittedSwabSchedule;
};

export const createBlackoutDate = async (
  blackoutDateRequest: BlackoutDateCreateProps
): Promise<void | DuplicateResponse> => {
  const blackoutDate: void | DuplicateResponse = await fetchJson(
    '/api/swab-schedules/blackout-dates',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...blackoutDateRequest
      })
    },
    'BlackoutDate'
  );
  return blackoutDate;
};

export const listBlackoutDates = async (
  params: BlackoutDatePaginatParamsT
): Promise<BlackoutDatePaginateResponse> => {
  const { limit, offset, sort, locationId, ...rest } = params;
  const url = `/api/swab-schedules/blackout-dates?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    locationId,
    ...rest
  })}&sort=${sort}`;
  const blackoutDates = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return blackoutDates;
};

export const deleteBlackoutDate = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/swab-schedules/blackout-dates/${id}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    },
    'BlackoutDate'
  );
  return removed;
};
