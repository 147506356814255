import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DndWidget } from './DndWidget';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { ReportView } from 'modules/reports/domain/ReportView';
import { arraysEqual } from 'utils/array';
import React, { FC, useState } from 'react';

export interface Item {
  index: number;
  report: ReportView;
  cols: number;
}

export interface WidgetContainerType {
  items: Item[];
  saveReportConfig: (report: ReportView, newConfig: any) => void;
  saveReportOrder: (items: Item[]) => void;
  removeReport: (id: string) => void;
  addNewReport: (report: ReportView) => void;
  canViewReports: boolean;
  canEditReports: boolean;
}

const getItemsOrder = (items: Item[]) =>
  items ? items.map(i => i.report.id) : [];
const buildGrid = items => {
  const grid: any[] = [];
  let x = 0;
  let y = 0;
  items?.forEach(card => {
    //determine if we can place this card here
    if (x + card.cols > 12) {
      x = 0;
      y += 1;
    }
    grid.push({ w: card.cols, x, y });
    x += card.cols;
    if (x >= 12) {
      y += 1;
      x = 0;
    }
  });
  return grid;
};
const ResponsiveGridLayout = WidthProvider(Responsive);
export const WidgetContainer: FC<WidgetContainerType> = ({
  saveReportOrder,
  items,
  canEditReports,
  ...rest
}) => {
  const [cards, setCards] = useState(items);
  const itemOrder = getItemsOrder(items);

  const onTilesReordered = (reorderedData: Layout) => {
    const sorted = reorderedData
      .sort((a, b) => a.x - b.x)
      .sort((a, b) => a.y - b.y);
    const newOrder = sorted
      .map(gridItem => gridItem.i)
      .filter(i => i !== 'new');
    if (!arraysEqual(newOrder, itemOrder)) {
      saveReportOrder(newOrder);
      const newCards = newOrder
        .map((id, i) => {
          const card = cards?.find(c => c.report.id === id);
          return {
            ...card,
            index: i
          };
        })
        .filter(card => card);
      setCards(newCards);
    }
  };

  const renderCard = (data, x, y) => (
    <div
      key={data.report.id}
      data-grid={{
        x,
        y,
        w: data.cols,
        h: 1,
        isResizable: false
      }}
    >
      <DndWidget
        id={data.report.id}
        report={data.report}
        {...rest}
        canEditReports={canEditReports}
      />
    </div>
  );

  const children = React.useMemo(() => {
    const grid = buildGrid(cards);
    const items =
      cards && cards.length
        ? cards.map((card, key) => {
            if (!card.report) return null;
            const gridItem = grid[key];
            const cardEl = renderCard(card, gridItem.x, gridItem.y);
            return cardEl;
          })
        : [];
    return items;
  }, [cards?.length]);
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <ResponsiveGridLayout
        rowHeight={450}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
        width={1200}
        isResizable={false}
        isDraggable={true}
        onDragStop={onTilesReordered}
        isBounded={true}
        margin={[10, 10]}
        compactType={'horizontal'}
        useCSSTransforms={false}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
};
