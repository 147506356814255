export interface DefaultReportConfig {
  name: string;
  filters: {
    interval: string;
    dateRange: {
      type: string;
    };
    dataType?: string;
    chartType?: string;
    rollingInterval?: string;
  };
  isDefault: boolean;
  dashboardOnly?: boolean;
  individualSites?: boolean;
  byZone?: boolean;
  showOnDashboard?: boolean;
  notificationOnly?: boolean;
  tableHeaders?: string[];
}
export const defaultReports: DefaultReportConfig[] = [
  {
    name: 'ResultsByOrganism',
    filters: {
      interval: 'month',
      dateRange: {
        type: 'year'
      }
    },
    dashboardOnly: true,
    showOnDashboard: true,
    isDefault: true
  },
  {
    name: 'Nonconformances',
    filters: {
      interval: 'week',
      dateRange: {
        type: 'month'
      }
    },
    dashboardOnly: true,
    showOnDashboard: true,
    isDefault: true
  },
  {
    name: 'Overall % Rolling Incidence Rate',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'year'
      },
      dataType: 'rolling',
      chartType: 'line',
      rollingInterval: 'thirtyDay'
    },
    showOnDashboard: true,
    isDefault: true
  },
  {
    name: 'Individual Sampling Site % Rolling Incidence Rate',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'year'
      },
      dataType: 'rolling',
      chartType: 'line',
      rollingInterval: 'ninetyDay'
    },
    individualSites: true,
    showOnDashboard: true,
    isDefault: true
  },
  {
    name: 'Overall % Rolling Incidence Rate By Zone',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'year'
      },
      dataType: 'rolling',
      chartType: 'line',
      rollingInterval: 'ninetyDay'
    },
    byZone: true,
    showOnDashboard: true,
    isDefault: true
  },
  {
    name: '12 Month Rolling % Incidence',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'year'
      },
      dataType: 'rolling',
      chartType: 'line',
      rollingInterval: 'thirtyDay'
    },
    showOnDashboard: true,
    isDefault: true
  }
];

export const MAX_DASHBOARD_WIDGETS = 10;

export const defaultNovalumReports: DefaultReportConfig[] = [
  {
    name: 'Daily Novalum Report',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'day'
      },
      dataType: 'calendar',
      chartType: 'custom'
    },
    notificationOnly: true,
    showOnDashboard: false,
    isDefault: true,
    tableHeaders: [
      'planName',
      'ebacmapCode',
      'site',
      'resultValue',
      'limit',
      'resultValue',
      'sampleDate',
      'user',
      'notes'
    ]
  },
  {
    name: 'Weekly Novalum Report',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'week'
      },
      dataType: 'calendar',
      chartType: 'custom'
    },
    notificationOnly: true,
    showOnDashboard: false,
    isDefault: true,
    tableHeaders: [
      'planName',
      'ebacmapCode',
      'site',
      'resultValue',
      'limit',
      'resultValue',
      'sampleDate',
      'user',
      'notes'
    ]
  },
  {
    name: 'Monthly Novalum Report',
    filters: {
      interval: 'day',
      dateRange: {
        type: 'month'
      },
      dataType: 'calendar',
      chartType: 'custom'
    },
    notificationOnly: true,
    showOnDashboard: false,
    isDefault: true,
    tableHeaders: [
      'planName',
      'ebacmapCode',
      'site',
      'resultValue',
      'limit',
      'resultValue',
      'sampleDate',
      'user',
      'notes'
    ]
  }
];
