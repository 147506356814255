import { Loading } from 'components/Layout/Loading';
import { MAX_DASHBOARD_WIDGETS } from 'modules/reports/DefaultReports';

import { PermissionActionValue } from 'modules/users/domain/PermissionAction';
import { PermissionDomainValue } from 'modules/users/domain/PermissionDomain';
import { ReportView } from 'modules/reports/domain/ReportView';
import { UserRoleValue } from 'modules/users/domain/UserRole';
import { WidgetContainer } from 'components/Dashboard/WidgetContainer';
import { bindActionCreators } from 'redux';
import { buildReportFilters } from 'utils/reportFilters';
import { connect } from 'react-redux';
import {
  createReport,
  getReport,
  listDashboardReports,
  saveDashboardReport,
  updateReport
} from 'services/reports';
import { hasPermission } from 'utils/permissions';
import { namespaceConfig } from 'fast-redux';
import { reduxPage } from 'hooks/withRedux';
import { updateDashboardWidgets } from 'services/users';
import { useBetween } from 'use-between';
import { useHasChanged, useLocation, useUser } from 'hooks';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const NewReportModule = dynamic(
  () => import('components/Report/NewReportModule')
);

type DashboardPropsT = {
  reports: ReportView[];
  defaultReports: ReportView[];
  loaded: boolean;
  isFetching: boolean;
  getReports: (locationId: string | null) => void;
  getDefaultReports: (locationId: string | null) => void;
  getFacilityMaps: (location) => any;
  saveReport: (report: ReportView) => void;
  resetDashboard: () => void;
};

const DEFAULT_STATE = {
  reports: [],
  defaultReports: [],
  loaded: false,
  isFetching: false,
  loadedDefault: false,
  isFetchingDefault: false
};

const { action, getState: getReportsState } = namespaceConfig(
  'dashboardReports',
  DEFAULT_STATE
);

const resetDashboard = action('dashboardReports', () => ({
  ...DEFAULT_STATE
}));

const requestReports = action('requestReports', state => ({
  ...state,
  loaded: false,
  isFetching: true
}));

const receiveReports = action('receiveReports', (state, reports) => ({
  ...state,
  isFetching: false,
  loaded: true,
  reports
}));

const reportUpdated = action('reportUpdated', (state, report) => {
  const reports = [...state.reports].map(r => {
    if (r.id === report.id) {
      return report;
    }
    return r;
  });
  return {
    ...state,
    isFetching: false,
    loaded: true,
    reports
  };
});

const saveReport = report => async dispatch => {
  dispatch(requestReports());
  if (!report.id || report.id === 'new') {
    await createReport(report, false, 'Dashboard Widget');
    dispatch(getReports(report.location));
  } else {
    await updateReport(report);
    const updatedReport: ReportView = await getReport(report.id);
    dispatch(reportUpdated(updatedReport));
    dispatch(getReports(report.location));
  }
};

const getReports = locationId => async dispatch => {
  if (!locationId) {
    dispatch(receiveReports([]));
    return;
  }
  dispatch(requestReports());
  const reports: ReportView[] = await listDashboardReports(locationId);

  dispatch(receiveReports(reports));
};

const reportObjects = [
  {
    name: 'eBacMap',
    description: 'New eBacMap facility report',
    image: '/images/map.png',
    allowedFilters: [
      'title',
      'category',
      'organism',
      'test',
      'zone',
      'dateRange',
      'reportType'
    ]
  },
  {
    name: '# of Tests By Subcategory',
    description: 'Results, per month, by subcategory',
    image: '/images/bar-chart.png',
    allowedFilters: []
  },
  {
    name: 'Nonconformances',
    description: 'Number of nonconformances for the previous 7 and 30 days',
    image: '/images/nonconformances.png',
    allowedFilters: []
  },
  {
    name: 'Overall % Rolling Incidence Rate',
    description: 'Overall percent of incidence',
    image: '/images/line-chart.png',
    allowedFilters: [
      'title',
      'category',
      'organism',
      'test',
      'zone',
      'dateRange',
      'interval',
      'dataType',
      'chartType',
      'rollingInterval'
    ]
  },
  {
    name: 'Individual Sampling Site % Rolling Incidence Rate',
    description: 'Percent of incidence by sampling sites',
    image: '/images/line-chart.png',
    individualSites: true,
    allowedFilters: [
      'title',
      'category',
      'organism',
      'test',
      'zone',
      'dateRange',
      'interval',
      'dataType',
      'chartType',
      'rollingInterval'
    ]
  },
  {
    name: 'Overall % Rolling Incidence Rate By Zone',
    description: 'Percent of incidence by zone',
    image: '/images/line-chart.png',
    byZone: true,
    allowedFilters: [
      'title',
      'category',
      'organism',
      'test',
      'zone',
      'dateRange',
      'interval',
      'dataType',
      'chartType',
      'rollingInterval'
    ]
  }
];

const SwabReportConfig = {
  name: 'Sample Scheduler',
  description: 'Shows past due and upcoming sample schedules',
  image: '/images/swab_dash_widget.png',
  allowedFilters: []
};

const CapaReportConfig = {
  name: 'CAPA',
  description: 'Shows open and closed CAPAs as well as non-conformances',
  image: '/images/capa_dash_widget.png',
  allowedFilters: []
};

const Tour360ReportConfig = {
  name: '360 Tour',
  description: 'New 360 tour report',
  image: '/images/360-logo.png',
  allowedFilters: [
    'title',
    'category',
    'organism',
    'test',
    'zone',
    'dateRange',
    'reportType'
  ]
};

const Dashboard = (props: DashboardPropsT) => {
  const {
    reports,
    isFetching,
    loaded,
    getReports,
    saveReport,
    resetDashboard
  } = props;
  const useBetweenLocation = () => useBetween(useLocation);
  const [location] = useBetweenLocation();
  const { user } = useUser();
  const router = useRouter();
  const [canViewReports, setCanViewReports] = useState<boolean>(true);
  const [canEditReports, setCanEditReports] = useState<boolean>(false);
  const [hasSwabView, setHasSwabView] = useState<boolean>(false);
  const [hasCapaView, setHasCapaView] = useState<boolean>(false);
  const locationHasChanged = useHasChanged(location?.id);

  useEffect(() => {
    if (
      user &&
      (user.role === UserRoleValue.Admin ||
        user.role === UserRoleValue.CustomerSupport)
    ) {
      router.push('/admin');
      return;
    }

    if (user && location) {
      setHasCapaView(
        hasPermission(
          user,
          location.id,
          PermissionDomainValue.Capa,
          PermissionActionValue.Manage
        )
      );
      setCanEditReports(
        hasPermission(
          user,
          location.id,
          PermissionDomainValue.Report,
          PermissionActionValue.Manage
        )
      );
      setHasSwabView(
        hasPermission(
          user,
          location.id,
          PermissionDomainValue.SwabSchedule,
          PermissionActionValue.View
        )
      );
    }
  }, [user, locationHasChanged, location]);

  useEffect(() => {
    if (
      (!isFetching && !loaded && location) ||
      (location && locationHasChanged && !isFetching)
    ) {
      if (
        user &&
        (user.role === UserRoleValue.Admin ||
          user.role === UserRoleValue.CustomerSupport)
      ) {
        return;
      }
      if (
        user &&
        (user.role === UserRoleValue.User ||
          user.role === UserRoleValue.RestrictedUser)
      ) {
        //check if we have permission to view reports before fetching
        if (
          !hasPermission(
            user,
            location.id,
            PermissionDomainValue.Report,
            PermissionActionValue.View
          )
        ) {
          setCanViewReports(false);
          getReports(null);
          return;
        }
      }
      setCanViewReports(true);
      getReports(location.id);
    }
  }, [isFetching, loaded, locationHasChanged]);

  useEffect(() => {
    return () => {
      // cancelAllRequests();
      // this gets called on leave
      resetDashboard();
    };
  }, []);

  const saveReportConfig = (report, newConfig) => {
    const filters = { ...report.filters };
    Object.keys(newConfig).forEach(key => {
      const value = newConfig[key];
      if (key !== 'name') {
        if (key === 'start' || key === 'end') {
          filters.dateRange[key] = value;
        } else if (key === 'dateRange' && typeof value === 'string') {
          filters.dateRange.type = value;
        } else if (key === 'dateRange') {
          filters.dateRange = value;
        } else {
          filters[key] = value;
        }
      }
    });
    if (filters.dateRange) {
      if (
        filters.dateRange.type === 'custom' &&
        (!filters.dateRange.start || !filters.dateRange.end)
      ) {
        return false;
      }
    }
    saveReport({
      ...report,
      name: newConfig.name,
      filters: buildReportFilters(filters)
    });
  };

  const removeReport = async (id: string) => {
    const report = reports.find(r => r.id === id);
    if (!report) {
      return;
    }
    await saveDashboardReport({
      id: report.id,
      showOnDashboard: false
    });
    getReports(location!.id);
  };

  const saveReportOrder = async items => {
    await updateDashboardWidgets(items);
    // getReports(location!.id);
  };

  const addNewReport = (newReport: ReportView) => {
    const filters = {
      interval: 'day',
      reportType: '',
      dataType: 'rolling',
      rollingInterval: 'sevenDay',
      chartType: 'line',
      advanced: false,
      conditions: [],
      dateRangeType: '',
      startDate: new Date(),
      endDate: new Date()
    };
    let reportVals = {
      id: 'new',
      showOnDashboard: true,
      name: newReport.name,
      filters,
      location: location?.id!,
      client: user!.client!
    };
    Object.keys(newReport).forEach(key => {
      const value = newReport[key];
      if (
        key !== 'name' &&
        key !== 'byZone' &&
        key !== 'individualSites' &&
        key !== 'isDefault' &&
        key !== 'dashboardOnly' &&
        key !== 'facilityMapId'
      ) {
        filters[key] = value;
      } else if (
        key === 'byZone' ||
        key === 'individualSites' ||
        key === 'isDefault' ||
        key === 'dashboardOnly' ||
        key === 'facilityMapId'
      ) {
        reportVals[key] = value;
      }
    });
    saveReport({
      ...newReport,
      location: location!.id!,
      client: user!.client!,
      showOnDashboard: true,
      filters: buildReportFilters(filters)
    });
  };

  if (!loaded) {
    return <Loading />;
  }
  if (!canViewReports) {
    return <div />;
  }
  const canAddReports = reports?.length < MAX_DASHBOARD_WIDGETS;
  return (
    <>
      {canEditReports ? (
        <NewReportModule
          allowNew={canAddReports}
          totalWidgets={reports?.length}
          saveCallback={addNewReport}
          location={location}
          reportObjects={[
            ...reportObjects,
            ...(hasSwabView ? [SwabReportConfig] : []),
            ...(hasCapaView ? [CapaReportConfig] : []),
            ...(location?.tourEnabled ? [Tour360ReportConfig] : [])
          ]}
        />
      ) : null}
      <WidgetContainer
        items={reports?.map((report, index) => ({
          index,
          report,
          cols:
            report.name === 'ResultsByOrganism'
              ? 9
              : report.name === 'Nonconformances'
              ? 3
              : 4
        }))}
        canEditReports={canEditReports}
        canViewReports={canViewReports}
        saveReportConfig={saveReportConfig}
        removeReport={removeReport}
        saveReportOrder={saveReportOrder}
        addNewReport={addNewReport}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { isFetching, loaded, reports } = getReportsState(state);
  return {
    isFetching,
    loaded,
    reports
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getReports,
      saveReport,
      resetDashboard
    },
    dispatch
  );
}

export default reduxPage(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
