import { CapaDashboardResponse } from 'modules/capas/use-cases/GetCapaDashboard';
import { CapaExistingView } from 'modules/capas/use-cases/ListExistingCapas/types';
import { CapaGetView } from 'modules/capas/use-cases/GetCapa/types';
import { CapaLogConfig } from 'modules/capas/domain/types';
import { CapaPaginateResponse } from 'modules/capas/repos/types';
import { PaginateParamsT } from 'common/PaginationTypes';
import fetchJson from 'utils/fetchJson';

export const paginateCapas = async (
  params: PaginateParamsT & {
    location?: string;
    isExport?: boolean;
  }
): Promise<CapaPaginateResponse> => {
  const { limit, offset, location, sort, ...rest } = params;
  //@ts-ignore //weird type issue
  const url = `/api/capas?${new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    location,
    ...rest,
    ...(sort && { sort: sort })
  })}`;
  const capas = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return capas;
};

export const getExistingCapas = async (
  locationId: string
): Promise<CapaExistingView[]> => {
  const capas = await fetchJson(
    `/api/capas/existing?locationId=${locationId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return capas;
};

export const getCapaById = async (url: string): Promise<CapaGetView> => {
  const capa = await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
  return capa;
};

export const createCapa = async (capa): Promise<string> => {
  const capaId = await fetchJson(
    '/api/capas',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...capa
      })
    },
    'Capa'
  );
  return capaId;
};

export const updateCapa = async (capa): Promise<void> => {
  const updatedCapa = await fetchJson(
    `/api/capas/${capa.id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...capa
      })
    },
    'Capa'
  );
  return updatedCapa;
};

export const reopenCapa = async (
  reopenReason: string,
  lastLog: CapaLogConfig,
  id: string
): Promise<void> => {
  const reopenedCapa = await fetchJson(
    `/api/capas/${id}/reopen/`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        reopenReason,
        lastLog
      })
    },
    'Capa'
  );
  return reopenedCapa;
};

export const closeCapa = async (
  id: string,
  updateReopen: boolean,
  lastLog?: CapaLogConfig,
  previousLog?: CapaLogConfig
): Promise<void> => {
  const closedCapa = await fetchJson(
    `/api/capas/${id}/close/`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        lastLog: lastLog ? lastLog : undefined,
        previousLog: previousLog ? previousLog : undefined,
        updateReopen
      })
    },
    'Capa'
  );
  return closedCapa;
};

export const deleteCapa = async (id: string): Promise<void> => {
  const removed = await fetchJson(
    `/api/capas/${id}`,
    {
      method: 'DELETE'
    },
    'Capa'
  );
  return removed;
};

export const getCapaDashboardReport = async (
  locationId: string
): Promise<CapaDashboardResponse> => {
  const url = `/api/capas/dashboard?locationId=${locationId}`;
  return await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
};

export const updateCapaTestResult = async (
  id: string,
  testResultId: string
): Promise<void> => {
  const reopenedCapa = await fetchJson(
    `/api/capas/${id}/test-result/`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        testResult: testResultId
      })
    },
    'Capa'
  );
  return reopenedCapa;
};

export const getCapaPDFReport = async (id: string): Promise<Buffer> => {
  const url = `/api/capas/${id}/pdf`;
  return await fetchJson(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });
};

export const getLastLogNumber = async (
  clientId: string,
  locationId: string
): Promise<string | undefined> => {
  const logNumber = await fetchJson(
    `/api/capas/log-number?locationId=${locationId}&clientId=${clientId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );
  return logNumber;
};
